import CaseStudyBlade from "components/common/CaseStudyBlade";
import DemoFormSection from "components/common/DemoFormSection";
import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";

export default function EducationLadningPageB() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        title: "Create Safer Schools with Rhombus",
        paragraph:
          "Safeguard students with a cloud-based platform that makes it easy to manage security across your entire campus or district. Proactively prepare for emergencies and respond swiftly when threats arise. ",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
      },
      logos: [
        {
          img: data.logo1,
          width: "89px",
          alt: "Clark Atlanta Univeristy",
        },
        {
          img: data.logo2,
          width: "118px",
          alt: "Jesuit",
        },
        {
          img: data.logo3,
          width: "135px",
          alt: "Mississippi Department of Education",
        },
        {
          img: data.logo4,
          width: "99px",
          alt: "Pacific University Oregon",
        },
        {
          img: data.logo5,
          width: "174px",
          alt: "Conestoga Valley",
        },
        {
          img: data.logo6,
          width: "158px",
          alt: "Los Angeles Film School",
        },
      ],
      infoSlider: {
        title: "The Benefits of Rhombus",
        list: [
          {
            tabTitle: "User-Friendly",
            title:
              "Keep Students and Faculty Safe with an Easy-to-Use Platform",
            description:
              "Fortify school security with cloud-based cameras and real-time AI alerts for proactive threat detection anytime, anywhere. Cover blind spots, support School Resource Officers, and streamline investigations with easy-to-use tools.",
            image: data.infoSlide1,
          },
          {
            tabTitle: "Accelerated Response",
            title:
              "Accelerate Emergency Response and Coordinate with First Responders",
            description:
              "Act swiftly in emergencies and access a range of solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats, reduce false alarms, and relay key details to emergency services efficiently.",
            image: data.infoSlide2,
          },
          {
            tabTitle: "Monitor Air Quality",
            title: "Monitor Air Quality and Detect Student Vaping",
            description:
              "Maintain a healthy school environment and reduce student vaping with air quality and vape/smoke/THC smart sensors. Automatically detect e-cigarette and tobacco use in real time and obtain actionable evidence while preserving student privacy. ",
            image: data.infoSlide3,
          },
          {
            tabTitle: "Building Management",
            title: "Control Campus Perimeters and Building Access",
            description:
              "Centrally manage unlimited doors, buildings, and credentials from anywhere. Detect unauthorized visitors and quickly understand who’s on school property by pairing access events with synchronized video, badge data, and timestamps. ",
            image: data.infoSlide4,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "Protect Students and Staff",
          title: "Protect Students and Staff",
          p:
            "Increase campus-wide visibility with high-resolution video and AI analytics to quickly identify, deter, and resolve unwanted events",
        },
        {
          icon: data.icon2,
          iconAlt: "Swiftly Respond to Incidents",
          title: "Swiftly Respond to Incidents",
          p:
            "Receive proactive real-time alerts for unusual behavior and share live footage with emergency services from the Rhombus Mobile App.",
        },
        {
          icon: data.icon3,
          iconAlt: "Stay Ahead of Threats",
          title: "Stay Ahead of Threats",
          p:
            "Utilize industry-leading AI analytics across all devices to receive proactive alerts for unauthorized people, smoking or vaping, and flagged vehicles.",
        },
        {
          icon: data.icon4,
          iconAlt: "Simplify Management",
          title: "Simplify Management",
          p:
            "No DVR/NVRs or complex configurations required. Cameras come online in minutes, include native remote access, and update automatically.",
        },
      ],
      caseStudyBlade: {
        image: data.bladeImage,
        logo: data.caseStudyLogo,
        author: {
          name: "Dr. Bo Tang",
          role: "Director of Enterprise Architecture at CAU",
          review:
            "I have been in the technology industry for many years, and I like the way Rhombus does business. They genuinely care about creating a product and service that fits their users’ needs.",
        },
      },
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus can transform your physical security.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Create Safer Schools | Secure Your Campus | Rhombus™</title>
          <meta
            name="description"
            content="Proactively safeguard students with a cloud-based platform that alerts responders in real time when threats arise."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2 data={pageData.headerSection} theme="light" />
        <IndustryFeaturedUpdated
          logos={pageData.logos}
          title="Trusted by Education Leaders "
          color="var(--white)"
        />
        <SectionContainer style={{ paddingTop: 0 }}>
          <SectionInner>
            <SectionCol>
              <StaticImage
                alt="Rhombus Dome Camera On Campus"
                loading="eager"
                src="../../../components/landing-pages/search/img/protect-students-min.png"
                placeholder="blurred"
                style={{ width: "100%" }}
              />
            </SectionCol>
            <FlexEndColumn>
              <TextContainer style={{ maxWidth: "500px" }}>
                <FancyTitleMed style={{ textAlign: "left" }}>
                  Protect Students and Staff Like Never Before
                </FancyTitleMed>
                <MainParagraph>
                  From primary schools to universities to school districts,
                  Rhombus provides best-in-class physical security solutions
                  tailored to your school’s unique needs.
                </MainParagraph>
              </TextContainer>
            </FlexEndColumn>
          </SectionInner>
        </SectionContainer>
        <InfoSlider
          data={pageData.infoSlider.list}
          title={pageData.infoSlider.title}
          color="var(--nuetral-100)"
          button
        />
        <Features
          data={pageData.features}
          title="What Sets Rhombus Apart"
          color="var(--white)"
        />
        <TrustedPartnerSection button={false} color="var(--nuetral-100)" />
        <CaseStudyBlade
          data={pageData.caseStudyBlade}
          height="222px"
          width="302px"
        />
        <DemoFormSection data={pageData.formSection} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/education/education-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/education/education-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: { eq: "components/industries/img/education/logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/education/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/education/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/education/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/industries/img/education/logo-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/education/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/industries/updated/img/education/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/edu-alarm-monitoring.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/industries/updated/img/education/environmental-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/industries/updated/img/education/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: {
          eq: "components/industries/img/icons/certification.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/updates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: { eq: "components/common/logos/cau-logo-large.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/common/img/case-studies/10-federal-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
